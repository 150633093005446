// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { Card } from "primereact/card";
import PVACDMap from "./PVACDMap"
//import MapComponent from "../../Map/MapComponent";
import { pvacd_gw_locations } from "../Groundwater/Sources";
import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { mToFt, retrieveItems } from "../../../util";
import Plot from "react-plotly.js";
import { ProgressSpinner } from "primereact/progressspinner";
const sources = [pvacd_gw_locations];

export default function PVACDDashboard() {
  const [sourceData, setSourceData] = useState({});
  const [locations, setLocations] = useState([]);
  const [enhancedLocations, setEnhancedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();

  const [popupContent, setPopupContent] = useState(undefined);
  //const [stickyPopup, setStickyPopup] = useState(false);

  const [layout, setLayout] = useState({
    autosize: true,
    plot_bgcolor: "",
    paper_bgcolor: "",
    margin: { t: 50, b: 100, l: 50, r: 50, pad: 4 },
    xaxis: { title: "Date" },
    yaxis: { title: "Depth to Water (ft bgs)", autorange: "reversed" },
  });

  useEffect(() => {
    console.log("sourceData", sourceData);
    if (!sourceData["weaver:gw:pvacd"]) return;
    console.log("sourceData", sourceData["weaver:gw:pvacd"].features);
    setLocations(sourceData["weaver:gw:pvacd"].features);
  }, [sourceData]);

  useEffect(() => {
    if (locations.length === 0) return;

    const fetchLatestObservations = async () => {
      const updatedLocations = await Promise.all(
        locations.map(async (location) => {
          const url = location.properties["selflink"] + "?$expand=Things/Datastreams";
          try {
            const response = await fetch(url);
            const data = await response.json();
            const datastreams = data.Things.map((t) => t.Datastreams).flat();

            const observationPromises = datastreams.map(async (ds) => {
              const obsUrl =
                ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc&$top=1";
              try {
                const obsResponse = await fetch(obsUrl);
                const obsData = await obsResponse.json();
                const observation = obsData.value[0];
                // console.log("observation", observation);
                return {
                  datastreamName: ds.name,
                  observation: observation,
                };
              } catch (error) {
                console.error("Error fetching observation", error);
                return null;
              }
            });

            const observations = await Promise.all(observationPromises);
            return {
              ...location,
              //id used for selection to map feature matching
              id: location.id,
              latestObservations: observations,
            };
          } catch (error) {
            console.error("Error fetching datastreams", error);
            return location;
          }
        })
      );

      setEnhancedLocations(updatedLocations);
    };

    fetchLatestObservations();
  }, [locations]);

  useEffect(() => {
    if (selectedLocations) {
      plot_hygrogaphs(selectedLocations);
    }
  }, [selectedLocations]);

  // Mouse move for map popup
  // const onMouseMove = (e, features, selected_points) => {
  //   const point = selected_points[0];
  //   setPopupContent({
  //     coordinates: e.lngLat.toArray(),
  //     children: 
  //       <h2>
  //         <strong>
  //           {point.properties.name}
  //         </strong>
  //       </h2>,
  //   });
  // };

  const onMapClick = (event, features) => {
    if (features && features.length > 0) {
      const clickedFeature = features[0];

      const selectedSite = enhancedLocations.find(
        (site) => site.properties.name === clickedFeature.properties.name
      );

      if (selectedSite) {
        setSelectedLocations([selectedSite]);

        setPopupContent({
          coordinates: clickedFeature.geometry.coordinates,
          children: (
            <h2>
              <strong>{clickedFeature.properties.name}</strong>
            </h2>
          ),
        });
      }
    }
  };

  const plot_hygrogaphs = (items) => {
    let promises = items.map((item) => {
      let url = item.properties["selflink"] + "?$expand=Things/Datastreams";
      // console.log("uads", item, url);
      return fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let datastreams = data.Things.map((t) => t.Datastreams).flat();
          console.log("datastreams", datastreams);
          let dspromise = datastreams.map((ds) => {
            return retrieveItems(
              ds["@iot.selfLink"] + "/Observations?$orderby=phenomenonTime desc",
              [],
            ).then((observations) => {
              return {
                mode: ds.name.includes("Manual") ? "markers" : "lines",
                x: observations.map((s) => s.phenomenonTime),
                y: observations.map((s) => s.result),
                name: `${item.properties.name} (${ds.name.includes("Manual") ? "Manual" : "Continuous"})`, 
              };
            });
          });
          return Promise.all(dspromise);
        });
    });

    setLoading(true);
    Promise.all(promises)
      .then((data) => {
        console.log("data", data.flat());
        setPlotData(data.flat());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={"grid justify-content-center"}>
      <div className={"grid col-12 m-3 md:col-10"}>
        <Card className={"col-12 mb-3 bg-blue-50"}>
          <h1 className={"text-center"}>
            Welcome to the PVACD Groundwater Dashboard
          </h1>
        </Card>
        <Card className={"col-12 mb-3"}
          subTitle={"Clicking a site on the map will select it in the table"}>
          <PVACDMap
            mapRef={mapRef}
            initialViewState={{ longitude: -104.516, latitude: 33.20, zoom: 8 }}
            style={{ width: "100%", height: "600px" }}
            sources={sources}
            sourceData={sourceData}
            setSourceData={setSourceData}
            popupContent={popupContent}
            setPopupContent={setPopupContent}
            onMapClickCallback={onMapClick}
            selectedLocations={selectedLocations}
          />
        </Card>
        <Card className={"col-12 mb-3"} title={"Summary Table"} subTitle={"Selected sites will be loaded onto the hydrograph and highlighted in red on the map"}>
          <DataTable
            size={"small"}
            value={enhancedLocations}
            selection={selectedLocations}
            dataKey="id"
            onSelectionChange={(e) => {
              setSelectedLocations(e.value);
          
              if (e.value && e.value.length === 1) {
                const selectedLocation = e.value[0];
                const coordinates = [
                  selectedLocation.geometry.coordinates[0],
                  selectedLocation.geometry.coordinates[1]
                ];
          
                setPopupContent({
                  coordinates: coordinates,
                  children: (
                    <h2>
                      <strong>{selectedLocation.properties.name}</strong>
                    </h2>
                  ),
                });
          
                //setStickyPopup(true);
              } else {
                //setStickyPopup(false);
                setPopupContent(undefined);
              }
            }}
            stripedRows
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
            <Column sortable field={"properties.name"} header={"Name"}></Column>
            <Column
              header="Latest Manual Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? manualObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header = "Latest Manual Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const manualObs = item.latestObservations.find(
                  (obs) => obs && obs.datastreamName.includes("Manual")
                );
                return manualObs && manualObs.observation
                  ? new Date(manualObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
            >
            </Column>
            <Column
              header="Latest Continuous Water Level (ft bgs)"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? continuousObs.observation.result.toFixed(2)
                  : "N/A";
              }}
            />
            <Column
              header="Latest Continuous Measurement Date"
              bodyStyle={{ textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={(item) => {
                const continuousObs = item.latestObservations.find(
                  (obs) => obs && !obs.datastreamName.includes("Manual")
                );
                return continuousObs && continuousObs.observation
                  ? new Date(continuousObs.observation.phenomenonTime).toLocaleDateString()
                  : "N/A";
              }}
              >
              </Column>
          </DataTable>
        </Card>
        <Card className="col-12" title="Hydrograph">
          <div
            className="grid justify-content-center relative"
            style={{
              width: '100%',
              height: '650px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div className="absolute bottom-50 right-50 z-1">
              {loading && <ProgressSpinner strokeWidth={5} />}
            </div>
            <div style={{ width: '100%', height: '100%' }}>
              <Plot
                data={plotData}
                layout={{
                  ...layout,
                  autosize: true,
                  legend: {
                    orientation: "h", 
                    yanchor: "top",   
                    y: -0.2,           
                    xanchor: "center", 
                    x: 0.5,    
                },
                }}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
// ============= EOF =============================================
