// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================
import MapComponent from "../../Map/MapComponent";
import PointsListDialog from "./PointsListDialog";
import { getSelectedFeatures, extractFeatures } from "../../Map/MapComponent";
import { mToFt, nmbgmr_getJson, nmbgmr_postJson } from "../../../util";
import DownloadControl from "./DownloadControl";
import { Panel } from "primereact/panel";
import { useEffect, useRef, useState } from "react";
import * as turf from "@turf/turf";
import { downloadCSV, downloadGeoJSON, downloadTSV } from "../../download_util";
import { settings } from "../../../settings";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Popup } from "react-map-gl";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { useFiefIsAuthenticated, useFiefTokenInfo } from "@fief/fief/react";
import FilterControl from "../../Map/FilterControl";
import StyleControl from "../../Map/StyleControl";
import BaseMapControl from "../../Map/BaseMapControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import NameSearchControl from "../../Map/NameSearchControl";
import LayerControl from "../../Map/LayerControl";
import {
  bernco_gw_locations,
  ebid_gw_locations,
  nmbgmr_gw_locations,
  pvacd_gw_locations,
  trends,
  usgs_nwis_gw_locations,
} from "./Sources";
import { Message } from "primereact/message";
import AMPAPIHydrograph from "../../LocationDetail/AMPAPIHydrograph";
import { OverlayPanel } from "primereact/overlaypanel";
import GenericHydrograph from "./GenericHydrograph";

import { isTouchDevice } from "../../../util";

export default function GroundwaterDashboard() {
  const tokenInfo = useFiefTokenInfo();
  const [selectionPolygons, setSelectionPolygons] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sourceData, setSourceData] = useState({});
  const [unfilteredSourceData, setUnfilteredSourceData] = useState({}); // [sourceId, sourceData
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);
  const [popupContent, setPopupContent] = useState(undefined);
  const [stickyPopup, setStickyPopup] = useState(false);
  const mapRef = useRef();
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/satellite-streets-v11",
  );
  const [showPointsDialog, setShowPointsDialog] = useState(false);
  const [dialogPoints, setDialogPoints] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const hydroOverlayRef = useRef();

  //Start  of action
  // const [highlightedPoint, setHighlightedPoint] = useState();
  // End of action
  useEffect(() => {
    if (Object.keys(sourceData).length === 0) {
      return;
    }
    if (selectionPolygons.length === 0) {
      return;
    }
    let allFeatures = getSelectedFeatures(
      { all_locations: false },
      sourceData,
      layerVisibility,
      selectionPolygons,
    );
    setSelectedLocations(allFeatures);
  }, [sourceData, selectionPolygons]);

  const handleLatestWaterLevels = async (options) => {
    // get all the selected features
    // send a request to the AMPAPI to get the water levels for selected features
    let sites = [];
    for (const [key, polygon] of Object.entries(selectionPolygons)) {
      sites.push(
        ...extractFeatures(sourceData, polygon, options.all_locations),
      );
    }
    let pointids = sites.filter((f) => f.data_source === "weaver:gw:nmbgmr");
    pointids = pointids.map((f) => f.properties.name);
    setLoading(true);
    nmbgmr_postJson("public/waterlevels/latest", pointids).then((res) => {
      console.log("water levels", res);
      let rows = res.map((item) => {
        return [
          item.site_id,
          item.depth_to_water_ftbgs,
          item.date_measured,
          item.time_measured,
        ];
      });

      let header = [
        "location",
        "depth_to_water_ftbgs",
        "date_measured",
        "time_measured",
      ];

      downloadCSV("latestwaterlevels", rows, header);
      setLoading(false);
      // let siteids = sites.filter((f) => f.data_source === "weaver:gw:usgs");
      // siteids = siteids.map((f) => f.properties.name);

      // get all the latest water levels from the USGS via AMP API
      // postJson(`${settings.apim_url}nwis/gw/waterlevels/latest`, siteids)
      //   .then((res) => {
      //     console.log("usgs water levels", res);
      //     // add the usgs water levels to the rows
      //     res.forEach((item) => {
      //       rows.push([
      //         item.site_id,
      //         item.depth_to_water_ftbgs,
      //         item.date_measured,
      //         "",
      //       ]);
      //     });
      //     // downloadCSV("latestwaterlevels", rows, header);
      //   })
      //   .finally(() => {
      //     console.log("inner");
      //     setLoading(false);
      //   });
    });
  };

  // const getSelectedFeatures = (options) => {
  //   let allFeatures = [];
  //   let sd = Object.keys(sourceData)
  //     .filter((key) => layerVisibility[key] === "visible")
  //     .reduce((res, key) => ((res[key] = sourceData[key]), res), {});
  //
  //   if (
  //     Object.keys(selectionPolygons).length > 0 &&
  //     options.all_locations !== true
  //   ) {
  //     for (const [key, polygon] of Object.entries(selectionPolygons)) {
  //       allFeatures.push(
  //         ...extractFeatures(sd, polygon, options.all_locations),
  //       );
  //     }
  //   } else {
  //     allFeatures = extractFeatures(sd, null, options.all_locations);
  //   }
  //   return allFeatures;
  // };

  const handleDownloadLocations = (options) => {
    let allFeatures = getSelectedFeatures(
      options,
      sourceData,
      layerVisibility,
      selectionPolygons,
    );

    const df = allFeatures.map((feature) => {
      const properties = feature.properties;
      const location = feature.geometry;
      return [
        feature.data_source,
        `"${properties.name}"`,
        location.coordinates[1],
        location.coordinates[0],
        properties.well_depth?.value,
        properties.hole_depth?.value,
        feature.url,
      ];
    });

    let header = [
      "data_source",
      "name",
      "latitude",
      "longitude",
      "well_depth",
      "hole_depth",
      "source_url",
    ];
    let func;
    switch (options.format.toLowerCase()) {
      case "csv":
        func = downloadCSV;
        break;
      case "tsv":
        func = downloadTSV;
        break;
      default:
        func = downloadCSV;
    }
    func("download", df, header);
  };

  const onDownload = (options) => {
    // if (options.water_levels === true) {
    //   toastRef.current.show({
    //     severity: "warn",
    //     summary: "Not Implemented",
    //     detail: "Downloading Water levels not implemented yet",
    //     life: 3000,
    //   });
    //   return;
    // }
    //
    // if (options.latest_only === true) {
    //   handleLatestWaterLevels(options);
    // } else if (options.water_levels_time_series === true) {
    //   toastRef.current.show({
    //     severity: "warn",
    //     summary: "Not Implemented",
    //     detail: "Downloading Water levels not implemented yet",
    //     life: 3000,
    //   });
    //   return;
    // }

    if (options.all_locations !== true) {
      if (Object.keys(selectionPolygons).length === 0) {
        toastRef.current.show({
          severity: "warn",
          summary: "No features selected",
          detail:
            "Please draw at least one polygon to select features." +
            " Or select the 'All Locations' checkbox to download all locations in New Mexico",
          life: 3000,
        });
        return;
      }
    }

    if (options.location_info !== true) {
      return;
    }

    handleDownloadLocations(options);
  };

  // mouse handlers
  const onMouseMove = (e, fs, sp) => {
    if (sp.length === 0) {
      return;
    }
  
    const coordinates = sp[0].geometry.coordinates;
    const sameLocationPoints = sp.filter(
      (point) => point.geometry.coordinates[0] === coordinates[0] && point.geometry.coordinates[1] === coordinates[1]
    );
  
    const sameLocationFeatures = fs.filter(
      (feature, index) => sameLocationPoints.includes(sp[index])
    );
  
    try {
      makePopupContent(sameLocationFeatures, sameLocationPoints);
    }
    //update error handling later
    catch (e) {
      console.error(e);
    }
  };

   //check for mobile device
   const isMobile = isTouchDevice();

   const makePopupContent = (selected_features, selected_points) => {
    //map the selected points to the data that will be displayed in the popup
    //selected_features is not used in this implementation but could be used in the future


    //-----MOBILE POPUP ------------
    if (isMobile) {
      const mobileContent = selected_points.map((point, index) => (
        <>
        <div>
        <div key={index} className={"text-center"} style={{ marginBottom: '1rem' }}>
          <h2>
            <span style={{ fontWeight: 'normal' }}>Point ID: </span>
            <strong>{point.properties.name}</strong>
          </h2>
          <Button
            label={"View Location Details"}
            icon={"pi pi-eye"}
            className={"button m-1"}
            onClick={() =>
              window.open(`/location/${point.properties.name}`, "_blank")
            }
          />
        </div>
        <hr></hr>
        </div>
        </>
      ));
  
      setPopupContent({
        coordinates: selected_points[0].geometry.coordinates,
        children: (
          <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {mobileContent}
          </div>
          <Button
            label="Close"
            icon="pi pi-times"
            className="p-button-secondary m-1"
            onClick={() => setPopupContent(undefined)}
          />
          </>
        ),
      });
      return;
    }
    //-----DESKTOP POPUP ------------
    const transposedData = [
      { key: "Name", values: selected_points.map(point => point.properties.name) },
      { key: "Elevation (ft)", values: selected_points.map(point => mToFt(point.geometry.coordinates[2])) },
      { key: "Well Depth (ft)", values: selected_points.map(point => point.properties.well_depth?.value) },
      { key: "Hole Depth (ft)", values: selected_points.map(point => point.properties.hole_depth?.value) },
      { key: "OSE Well ID", values: selected_points.map(point => point.properties.ose_well_id) },
      { key: "Alternate Site ID", values: selected_points.map(point => point.properties.alternate_site_id) },
      { key: "Site No", values: selected_points.map(point => point.properties.site_no) },
    ];
    //----------Keep previous tablesData with features - we may need to implement this type of feature use in the future-------------
    // const tablesData = selected_points.map((point, index) => {
    //   const feature = selected_features[index];
    //   const rows = [
    //     { key: "Name", value: point.properties.name },
    //     {
    //       key: "Elevation (ft)",
    //       value: mToFt(point.geometry.coordinates[2]),
    //     },
    //     {
    //       key: "Well Depth (ft)",
    //       value: point.properties.well_depth?.value,
    //     },
    //     {
    //       key: "Hole Depth (ft)",
    //       value: point.properties.hole_depth?.value,
    //     },
    //     ...(feature.source === "weaver:gw:nmbgmr" || feature.source === "weaver:gw:nmbgmr:authorized"
    //       ? [
    //           { key: "OSE Well ID", value: point.properties.ose_well_id },
    //           { key: "Site ID", value: point.properties.site_id },
    //           { key: "Alternate Site ID", value: point.properties.alternate_site_id },
    //         ]
    //       : feature.source === "weaver:gw:usgs"
    //       ? [{ key: "Site No", value: point.properties.site_no }]
    //       : []),
    //   ];
    //   return rows;
    // });

  const siteColumns = selected_points.map((point, index) => ({
    field: `site_${index}`,
    header: null,
    body: (rowData) => (
      <span className={"font-bold"}>
        {rowData.values[index] || ""}
      </span>
    )
  }));

  const propertyColumn = [
    { field: 'key', header: null }
  ];

  const columns = [...propertyColumn, ...siteColumns];

  setPopupContent({
    coordinates: selected_points[0].geometry.coordinates,
    children: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h3>Click for more details</h3>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <DataTable value={transposedData} size="small" stripedRows showGridlines={true} showHeaders={false} className="popup-table">
            {columns.map(col => (
              <Column key={col.field} field={col.field} header={col.header} body={col.body} className=""></Column>
            ))}
          </DataTable>
        </div>
      </div>
    ),
  });
};
// ----------- END OF MAKE POPUP CONTENT ------------

  // const flattenSourceData = (sourceData) => {
  //   let allFeatures = [];
  //   for (let [key, source] of Object.entries(sourceData)) {
  //     let features = source.features.map((feature) => {
  //       return {
  //         ...feature,
  //         data_source: source.properties.data_source,
  //         source_url: source.properties.url,
  //       };
  //     });
  //     allFeatures.push(...features);
  //   }
  //   return allFeatures;
  // };

  const onMouseClick = (e, selected_points) => {
    const coordinates = selected_points[0].geometry.coordinates;
    const sameLocationPoints = selected_points.filter(
      (point) =>
        point.geometry.coordinates[0] === coordinates[0] &&
        point.geometry.coordinates[1] === coordinates[1]
    );
  
    if (sameLocationPoints.length > 1) {
      setDialogPoints(sameLocationPoints);
      setShowPointsDialog(true);
    } else {
      window.open("/location/" + selected_points[0].properties.name, "_blank");
    }
  };

  const onContextMenu = (e, selected_points) => {
    const coordinates = selected_points[0].geometry.coordinates;
    const sameLocationPoints = selected_points.filter(
      (point) =>
        point.geometry.coordinates[0] === coordinates[0] &&
        point.geometry.coordinates[1] === coordinates[1]
    );
  
    if (sameLocationPoints.length > 1) {
      setDialogPoints(sameLocationPoints);
      setShowPointsDialog(true);
    } else {
      //console.log("map click", e);
      //console.log("asdfasdf", selected_points);
    }
  };

  let defaultLayerVisibility = {
    "weaver:gw:nmbgmr": "none",
    "weaver:gw:usgs": "none",
    "weaver:gw:nmbgmr:trends": "none",
    "weaver:gw:pvacd": "none",
    "weaver:gw:bernco": "none",
    "weaver:gw:ebid": "none",
  };
  let sources = [
    trends,
    nmbgmr_gw_locations,
    usgs_nwis_gw_locations,
    pvacd_gw_locations,
    bernco_gw_locations,
    ebid_gw_locations,
  ];
  if (tokenInfo && tokenInfo.access_token) {
    defaultLayerVisibility["weaver:gw:nmbgmr:authorized"] = "visible";
    sources.push({
      label: "NMBGMR Authorized",
      id: "weaver:gw:nmbgmr:authorized",
      dataFetcher: async () => {
        let data = await nmbgmr_getJson(
          "locations?only_private=true",
          tokenInfo?.access_token,
        );
        return data;
      },
      url: `${settings.nmbgmr_api_url}/locations`,
      paint: {
        "circle-radius": 4,
        "circle-color": "#79878f",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#0944dc",
      },
    });
  }
  const [layerVisibility, setLayerVisibility] = useState(
    defaultLayerVisibility,
  );

  // Start of Action Button
  const notImplemented = (summary) => {
    toastRef.current.show({
      severity: "warn",
      summary: summary,
      detail: "This feature is not yet implemented",
      life: 3000,
    });
  };

  const handleZoomButton = (rowData) => {
    console.log(
      "longitude",
      rowData.geometry.coordinates[0],
      "latitude",
      rowData.geometry.coordinates[1],
    );
    // setHighlightedPoint({
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       parseFloat(rowData.geometry.coordinates[0]),
    //       parseFloat(rowData.geometry.coordinates[1]),
    //     ],
    //   },
    // });

    mapRef.current.flyTo({
      center: [
        parseFloat(rowData.geometry.coordinates[0]),
        parseFloat(rowData.geometry.coordinates[1]),
      ],
      zoom: 12,
      transitionDuration: 2000,
    });
  };

  const actionButton = (rowData) => {
    return (
      <div className="p-inputgroup flex-1">
        <div className={"mr-1"}>
          <Button
            className={"small-button"}
            tooltip={"View Hydrograph"}
            icon={"pi pi-chart-line"}
            onClick={(e) => {
              // notImplemented("View");
              setSelectedLocation(rowData);
            }}
          />
        </div>
        <div className={"mr-1"}>
          <Button
            className={"small-button"}
            tooltip={"Zoom to location on map"}
            icon={"pi pi-eye"}
            onClick={(e) => {
              handleZoomButton(rowData);
            }}
          />
        </div>
        <div className={"mr-1"}>
          <Button
            className={"small-button"}
            tooltip={"Goto Location detail page"}
            icon={"pi pi-arrow-up-right"}
            onClick={(e) => {
              window.open("/location/" + rowData.properties.name, "_blank");
            }}
          />
        </div>
      </div>
    );
  };

  // End Action Button

  const onFilter = (filters) => {
    console.debug("do filter", filters);
    let sd = {};
    Object.entries(unfilteredSourceData).forEach(([id, source]) => {
      const nfeatures = unfilteredSourceData[id].features.filter((feature) => {
        const fs = Object.entries(filters).map(([key, value]) => {
          const fv = Object.byString(feature.properties, value.attribute);
          if (fv === null) {
            return false;
          }
          switch (value.comparator) {
            case "less than":
              return fv <= value.value;
            case "greater than":
              return fv >= value.value;
            case "equal":
              return fv === value.value;
            case "not equal":
              return fv !== value.value;
            case "starts with":
              return fv.startsWith(value.value);
            case "ends with":
              return fv.endsWith(value.value);
            case "contains":
              return fv.includes(value.value);
            default:
              return false;
          }
        });
        return fs.some((f) => f);
      });
      sd[id] = { ...source, features: nfeatures };
    });
    setSourceData(sd);
  };

  const handleNameSearch = (name) => {
    let found = false;
    for (let [id, source] of Object.entries(unfilteredSourceData)) {
      const selectedFeature = source.features.filter((feature) => {
        return feature.properties.name === name;
      });

      if (selectedFeature.length === 0) {
        continue;
      }
      found = true;

      setStickyPopup(true);
      makePopupContent(selectedFeature[0]);

      mapRef.current.flyTo({
        center: selectedFeature[0].geometry.coordinates,
        zoom: 14,
      });
    }

    if (!found) {
      toastRef.current.show({
        severity: "warn",
        summary: "Location not found",
        detail: `No location with name ${name} found`,
        life: 3000,
      });
    }
  };

  const handleLayerSelection = (checked) => {
    let vis = sources.reduce((acc, source) => {
      acc[source.id] = "none";
      if (checked[source.id] !== undefined) {
        acc[source.id] = checked[source.id].checked ? "visible" : "none";
      }
      return acc;
    }, {});
    console.log("vasda", vis);
    setLayerVisibility(vis);
  };

  //Define selected layers for a legend
  const getSelectedLayers = () => {
    return sources.filter(source => layerVisibility[source.id] === "visible");
  };

  const selectedLayers = getSelectedLayers();

  const handleLayerDownload = (key) => {
    let source = mapRef.current.getSource(key);
    // console.log(source._data);
    downloadGeoJSON(`${key}.geojson`, source._data);
  };

  // if (!useFiefIsAuthenticated() && settings.mode === "production") {
  //   return (
  //     <div className={"text-center"}>
  //       <Panel>
  //         <h1>NM Groundwater Dashboard</h1>
  //         <h3 className={""}>Under Construction</h3>
  //       </Panel>
  //     </div>
  //   );
  // }

  return (
    <div className={"grid justify-content-center"}>
      {loading && (
        <div className={"absolute bottom-10"}>
          <ProgressSpinner strokeWidth="8" />
        </div>
      )}
      <Toast ref={toastRef} />
      <PointsListDialog
        visible={showPointsDialog}
        onHide={() => setShowPointsDialog(false)}
        points={dialogPoints}
      />
      {/* <div className={"col-12"}>
        <div className={"text-center"}>
          <h1>NM Groundwater Dashboard</h1>
        </div>
      </div> */}
      <div className={"grid m-3 md:col-10 justify-content-center"}>
      <Card className={"col-12 mb-3"}>
        <div className={"col-12 surface-300"}>
          <div className={"text-center"}>
            <h2>🚧 Website Under Construction 🚧 </h2>
            <h3>We’re Building Something Amazing!</h3>
          </div>
          <div className={"flex justify-content-center"}>
            <p className={"w-12 md:w-8"}>
              Thank you for visiting the NM Groundwater Dashboard. We’re
              currently working hard to create a fantastic new experience for
              you. Our team is dedicated to bringing you the best, and we can’t
              wait to show you what’s in store. Check back soon for updates!
            </p>
          </div>
        </div>
      </Card>
      <Card className={"col-12 bg-blue-50"}>
      <h1
            style={{
              textAlign: "center",
            }}
          >
            NM Groundwater Dashboard
          </h1>
          <div className={"flex"}>
          <p>
          The NM Groundwater Dashboard is a tool that allows users to visualize and analyze groundwater data from various sources. The tool enables users to map and select specific monitoring sites or draw custom polygons to filter groundwater data spatially. The tool then aggregates data from multiple sources and presents a comprehensive dataset.
          </p>
          </div>
      </Card>
      </div>
      <div className={"grid col-12 md:col-10"}>
      <Card className={"col-12 md:col-8"}>
        <MapComponent
          mapStyle={mapStyle}
          selectionPolygons={selectionPolygons}
          setSelectionPolygons={setSelectionPolygons}
          setSourceData={(data) => {
            setSourceData(data);
            setUnfilteredSourceData(data);
          }}
          sourceData={sourceData}
          onMouseMoveCallback={onMouseMove}
          onMouseClickCallback={onMouseClick}
          onContextMenuCallback={onContextMenu}
          popupContent={popupContent}
          setPopupContent={setPopupContent}
          stickyPopup={stickyPopup}
          setStickyPopup={setStickyPopup}
          sources={sources}
          layerVisibility={layerVisibility}
          showDrawControls={{ show: true, position: "top-right" }}
          mapRef={mapRef}
        />
        {/* Legend */}
        <div className={"grid"}>
        <div className={"col-12 md:col-6 mt-3"}>
        <h4>Selected Layers:</h4>
            <div>
              {selectedLayers.length === 0 ? (
                <div>None</div>
              ) : (
                selectedLayers.map(layer => (
                  <div key={layer.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '15px',
                        height: '15px',
                        backgroundColor: layer.paint['circle-color'], // Assuming `circle-color` is used for all layers
                        marginRight: '10px',
                        borderRadius: '3px',
                      }}
                    ></span>
                    <span>{layer.label}</span>
                  </div>
                ))
              )}
            </div>
            </div>
            <div className={"col-12 md:col-6 md:mt-3 mt-0"}>
            {/* Trends legend */}
            {layerVisibility['weaver:gw:nmbgmr:trends'] === 'visible' && (
              <div>
                <h4>Trends Legend:</h4>
                <p>
                  <span style={{ backgroundColor: '#d7e1fc', padding: '2px 10px', marginRight: '5px' }}></span>
                  Stable
                </p>
                <p>
                  <span style={{ backgroundColor: '#0042f7', padding: '2px 10px', marginRight: '5px' }}></span>
                  Increasing (less depth to water) &uarr;
                </p>
                <p>
                  <span style={{ backgroundColor: '#fa6902', padding: '2px 10px', marginRight: '5px' }}></span>
                  Decreasing (more depth to water) &darr;
                </p>
              </div>
            )}
          </div>
          {layerVisibility['weaver:gw:nmbgmr:trends'] === 'visible' && (
            <div className={"col-12 mt-3"}>
                <Message severity="info" text="Trends are calculated using regression analysis on monitoring well measurements from NMBGMR where there are more than 4 observations over 2 years, or at least 10 total observations. Wells with with a slope of 0.25 were considered to be “Increasing”. Wells with less than -0.25 were considered to be “Decreasing”. Others are considered to be “Stable”." />
            </div>
          )}
          </div>
      </Card>
      <Card className={"col-12 md:col-4"}
        title={"Controls"}
      >
        <Accordion activeIndex={0}>
          <AccordionTab header={"Layers"}>
            <LayerControl
              layers={sources}
              layerVisibility={layerVisibility}
              handleLayerSelection={handleLayerSelection}
              handleLayerDownload={handleLayerDownload}
            />
          </AccordionTab>
          <AccordionTab header={"Base Map"}>
            <BaseMapControl
              mapRef={mapRef}
              mapStyle={mapStyle}
              setMapStyle={setMapStyle}
            />
          </AccordionTab>
          <AccordionTab header={"Download"}>
            <DownloadControl downloader={onDownload} />
          </AccordionTab>
          <AccordionTab header={"Filter"}>
            <FilterControl
              onFilter={onFilter}
              attributes={[
                { value: "well_depth.value", label: "Well Depth(ft)" },
                { value: "hole_depth.value", label: "Hole Depth(ft)" },
                { value: "name", label: "Name" },
              ]}
            />
          </AccordionTab>
          <AccordionTab header={"Location ID Search"}>
            <NameSearchControl onSearch={handleNameSearch} />
          </AccordionTab>
          <AccordionTab header={"Style"}>
            <StyleControl
              mapRef={mapRef}
              style={mapStyle}
              setStyle={setMapStyle}
            />
          </AccordionTab>
        </Accordion>
      </Card>
      </div>
      <div className={"grid col-12 md:col-10"}>
      <Card className={"col-12 mb-3"}
        title={"Table of Selected Groundwater Locations"}
      >
          <DataTable
            size={"small"}
            className={"compact-table"}
            paginator
            rows={10}
            value={selectedLocations}
            // value={flattenSourceData(sourceData)}
          >
            <Column
              sortable
              field={"data_source"}
              header={"Data Source"}
            ></Column>
            <Column sortable field={"properties.name"} header={"Name"}></Column>
            <Column
              sortable
              field={(item) => item.geometry.coordinates[1]}
              header={"Latitude"}
            ></Column>
            <Column
              sortable
              field={(item) => item.geometry.coordinates[0]}
              header={"Longitude"}
            ></Column>
            <Column
              // filter
              sortable
              field={(item) => mToFt(item.geometry.coordinates[2])}
              header={"Elevation"}
            ></Column>
            <Column
              // filter
              sortable
              field={"properties.well_depth.value"}
              header={"Well Depth"}
            ></Column>
            <Column
              // filter
              sortable
              field={"properties.hole_depth.value"}
              header={"Hole Depth"}
            ></Column>
            <Column field={"source_url"} header={"Source URL"}></Column>

            <Column header="Actions" body={actionButton} />
          </DataTable>
        </Card>
        <Card
          className={"col-12 mb-3"}
          title={"Hydrograph"}
        >
         {selectedLocation ? (
            <GenericHydrograph location={selectedLocation} />
          ) : (
            <div>No location selected</div>
          )}
        </Card>
      </div>
      </div>
  );
}
// ============= EOF =============================================
